import React, { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import logo from "../assets/logo.png";

const CustomNavbar = () => {
  const [activeTab, setActiveTab] = useState("home");
  const [navbarHeight, setNavbarHeight] = useState(0);
  const [expanded, setExpanded] = useState(false);

  const handleClick = (section) => {
    setActiveTab(section);
    const element = document.getElementById(section);
    const offset = navbarHeight + 20; // Adjust for additional space
    const elementPosition =
      element.getBoundingClientRect().top + window.scrollY;
    window.scrollTo({
      top: elementPosition - offset,
      behavior: "smooth",
    });
    setExpanded(false);
  };

  useEffect(() => {
    const updateNavbarHeight = () => {
      const navbar = document.querySelector(".navbar");
      setNavbarHeight(navbar ? navbar.offsetHeight : 0);
    };

    updateNavbarHeight();
    window.addEventListener("resize", updateNavbarHeight);

    return () => {
      window.removeEventListener("resize", updateNavbarHeight);
    };
  }, []);

  return (
    <Navbar expand="lg" className="navbar" expanded={expanded}>
      <Container>
        <Navbar.Brand href="#home" className="logo">
          <img src={logo} alt="Logo" />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={() => setExpanded(expanded ? false : "expanded")}
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto nav-links">
            {["home", "services", "values", "about", "packages", "contact"].map(
              (tab) => (
                <Nav.Link
                  key={tab}
                  className={activeTab === tab ? "active" : ""}
                  onClick={() => handleClick(tab)}
                >
                  {tab.charAt(0).toUpperCase() + tab.slice(1)}
                </Nav.Link>
              )
            )}
          </Nav>
          <div className="btn-wrapper d-flex justify-content-center">
            <a
              className="text-black"
              href="https://clinic.e-klinic.com/"
              target="_blank"
            >
              <button className="btn main-btn ms-lg-3">Login</button>
            </a>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default CustomNavbar;
