// src/components/Services.js
import React from "react";

const servicesData = [
  {
    title: "Mission",
    description:
"Our mission is to develop an intuitive and comprehensive ERP system that streamlines clinic operations and elevates patient experiences. We aim to provide cutting-edge tools that facilitate communication, improve workflow efficiency, and foster better health outcomes for communities worldwide.",
  },
  {
    title: "Vision",
    description:
      "To revolutionize healthcare management by seamlessly connecting clinics, doctors, and patients through innovative technology, enhancing the quality of care and empowering every stakeholder in the healthcare journey.",
  },
  // {
  //   title: "Value",
  //   description:
  //     "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
  // },
];

const Services = () => {
  return (
    <div
      className="container-fluid"
      style={{ paddingRight: "2%", paddingLeft: "2%" }}
    >
      <section className="services-section" id="services">
        <h2>Services</h2>
        <div className=" container ">
        <div className="row services-cards">

          {servicesData.map((service, index) => (
         
            <div  key={index}  className="service-card col-lg-4 col-md-4 col-sm-12">
              <h3>{service.title}</h3>
              <p  style={{textAlign:'justify'}}>{service.description}</p>
            </div>
          ))}</div>
        </div>
      </section>
    </div>
  );
};

export default Services;
