import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const PackageCard = ({ price, branches, doctors, staff }) => {
  return (
    <div className="package-card col-md-4 col-sm-12">
      <h3 className="package-name">Package Name</h3>
      <h2 className="package-price">{price} EGP <span className="month">/Month</span></h2>
      <ul className="package-details">
        <li className="d-flex justify-content-start">
          {" "}
          <div className="icon-wrapper-package">
            <FontAwesomeIcon icon={faCheck} />{" "}
          </div>{" "}
          {branches} Branches
        </li>
        <li className="d-flex justify-content-start">
          <div className="icon-wrapper-package">
            <FontAwesomeIcon icon={faCheck} />{" "}
          </div>{" "}
          {doctors} Doctors
        </li>
        <li className="d-flex justify-content-start">
          <div className="icon-wrapper-package">
            <FontAwesomeIcon icon={faCheck} />{" "}
          </div>{" "}
          {staff} Staff
        </li>
      </ul>
    </div>
  );
};

const Package = () => {
  const packages = [
    { price: "20", branches: "10", doctors: "20", staff: "35" },
    { price: "40", branches: "20", doctors: "40", staff: "45" },
    { price: "60", branches: "30", doctors: "50", staff: "55" },
  ];

  return (
    <div
      className="container-fluid"
      style={{ paddingRight: "2%", paddingLeft: "2%" }}
    >
      <section className="aboutus-section" id="packages">
        <h2> Packages</h2>
        <div className="package-list row gx-4">
          {packages.map((pkg, index) => (
            <PackageCard
              key={index}
              price={pkg.price}
              branches={pkg.branches}
              doctors={pkg.doctors}
              staff={pkg.staff}
            />
          ))}
        </div>
      </section>
    </div>
  );
};

export default Package;
