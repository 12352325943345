// src/components/Services.js
import React from "react";

const valuesData = [
  {
    title: "Patient-Centricity",
    description:
"We prioritize the needs and experiences of patients, ensuring they receive timely and effective care through our platform.",
  },
  {
    title: "Innovation",
    description:
      "We embrace creativity and forward-thinking to continuously enhance our software, staying ahead in a rapidly evolving healthcare landscape.",
  },
  {
    title: "Collaboration",
    description:
      "We believe in the power of teamwork—between clinics, healthcare providers, and patients—to achieve the best outcomes in healthcare delivery.",
  },
  {
    title: "Integrity",
    description:
      " We uphold the highest standards of ethics and transparency, ensuring trust and reliability in all our interactions.",
  },  {
    title: "Accessibility",
    description:
      "We strive to make healthcare management tools available and user-friendly for all clinics, regardless of size or resources, promoting equity in healthcare access.",
  },  {
    title: "Continuous Improvement",
    description:
      "We are committed to learning and evolving, regularly seeking feedback to refine our systems and processes.",
  },
  {
    title: "Data Security",
    description:
      "We prioritize the protection of sensitive health information, adhering to the highest standards of data security and privacy regulations.",
  },
];

const Values = () => {
  return (
    <div
      className="container-fluid"
      style={{ paddingRight: "2%", paddingLeft: "2%" }}
    >
      <section className="services-section" id="values">
        <h2>Our Values</h2>
        <div className=" container ">
        <div className="row services-cards">

          {valuesData.map((service, index) => (
            <div  key={index}  className="service-card col-lg-4 col-md-4 col-sm-12">
              <h3>{service.title}</h3>
              <p style={{textAlign:'justify'}}>{service.description}</p>
            </div>
          ))}</div>
        </div>
      </section>
    </div>
  );
};

export default Values;
