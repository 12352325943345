import TextField from "@mui/material/TextField";

const TextBox = (props) => {
  const {
    id,
    label,
    color,
    size,
    disabled,
    className,
    value,
    validation,
    setValue,
    name,
    type,
    helperText,
    required,
    placeholder,
    handleOnChange,
    oneId,
  } = props;
  const today = new Date().toISOString().split("T")[0];
  // On change Fn
  const onChange = (e) => {
    if (setValue) setValue((prev) => ({ ...prev, [name]: e.target.value }));
    else return;
    validation?.handleChange(e);
  };

  return (
    <TextField
      data-one-id={oneId}
      label={label}
      name={name}
      id={id}
      type={type || "text"}
      size={size}
      style={{ width: "100%" }}
      variant="standard"
      className={className}
      value={value}
      required={required}
      focused={value || disabled ? true : false}
      placeholder={placeholder}
      onChange={handleOnChange || onChange}
      disabled={disabled}
      color={color || "primary"}
      inputProps={{
        style: { lineHeight: "1 !important" },
        max: type === "date" ? today : undefined, // Set min attribute if type is date
      }}
      error={
        validation?.touched[name] && validation?.errors[name] ? true : false
      }
      helperText={
        validation?.touched[name] &&
        validation?.errors[name] &&
        (helperText ||
          validation?.errors[name] ||
          `${label[0].toUpperCase() + label.slice(1)} is required`)
      }
      {...props}
    />
  );
};

export default TextBox;
