import React from "react";
import logo from "../assets/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook } from "@fortawesome/free-brands-svg-icons"; 
import { faInstagram } from "@fortawesome/free-brands-svg-icons"; 
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons"; 
import { faXTwitter } from "@fortawesome/free-brands-svg-icons"; 
import { faSnapchat } from "@fortawesome/free-brands-svg-icons"; 
import { faYoutube } from "@fortawesome/free-brands-svg-icons"; 

const Footer = () => {
  return (
    <section className="footer-section">
      <div className="logo">
        <img src={logo} alt="Logo" /> {/* Replace with your logo path */}
      </div>
      <div className="icons row">
        <div className="icon-wrapper">
          <FontAwesomeIcon icon={faFacebook} style={{ color: "#fff" }} />{" "}
        </div>
        <div className="icon-wrapper">
          <FontAwesomeIcon icon={faInstagram}  style={{ color: "#fff" }} />{" "}
        </div>
        <div className="icon-wrapper">
          <FontAwesomeIcon icon={faLinkedinIn}  style={{ color: "#fff" }} />{" "}
        </div>
        <div className="icon-wrapper">
          <FontAwesomeIcon icon={faXTwitter}  style={{ color: "#fff" }} />{" "}
        </div>
        <div className="icon-wrapper">
          <FontAwesomeIcon icon={faSnapchat}  style={{ color: "#fff" }} />{" "}
        </div>
        <div className="icon-wrapper">
          <FontAwesomeIcon  icon={faYoutube}  style={{ color: "#fff" }} />{" "}
        </div>
      </div>
    </section>
  );
};

export default Footer;
