// src/components/HeroSection.js
import React from 'react';
import home from '../assets/Frame 12.png'

const HeroSection = () => {
  return (
    <section id="home">
      {/* <div className="hero-content">
        <img src={home} alt="Doctor" className="hero-image" />
        <div className="hero-overlay">
          <div className="hero-text">
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            <p>Lorem ipsum dolor sit amet.</p>
            <button className=" btn main-btn">Watch Video</button>
          </div>
        </div>
      </div> */}
 <div style={{ position: 'relative', paddingTop: '56.25%' /* 16:9 Aspect Ratio */ }}>
      <iframe
        src="https://www.youtube.com/embed/eAGRGx1q0eg?autoplay=1&mute=1"
        title="YouTube video"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          border: 'none',
        }}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>

    </section>
  );
};

export default HeroSection;
