import React, { useState } from "react";
import Dashboard from "../assets/Frame 4.png";
import Website from "../assets/Frame 5.png";
import Mobile from "../assets/Frame 6.png";
import Carousel from "react-bootstrap/Carousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const AboutUs = () => {
  const [activeTab, setActiveTab] = useState("website");

  const websiteContent = {
    images: [Dashboard, Website, Mobile],
    bulletPoints: [
      "Appointment Scheduling.",
      "Patient Management.",
      "Electronic Health Records (EHR).",
      "Billing and Invoicing.",
      "Telemedicine Integration.",
      "Prescription Management.",
      "Clinical Workflow Optimization.",
      "Analytics and Reporting.",
      "Patient Communication Tools.",
      "Compliance Management.",
      "Inventory Management.",
      "Training and Support.",
    ],
  };

  const mobileAppContent = {
    images: [Dashboard, Website, Mobile],
    bulletPoints: [
      "Appointment Booking.",
      "Medicine Access/ Reminders.",
      "Medical History Access.",
      "Secure Messaging.",
      "Nearby Labs/ Pharmacies.",
      "Educational Resources.",
      "Patient Feedback System.",
      "Billing and Payment.",
      "Appointment Reminders.",
      "Emergency Contact Information.",
    ],
  };

  const currentContent =
    activeTab === "website" ? websiteContent : mobileAppContent;

  // Split the bullet points into two halves
  const midIndex = Math.ceil(currentContent.bulletPoints.length / 2);
  const firstHalf = currentContent.bulletPoints.slice(0, midIndex);
  const secondHalf = currentContent.bulletPoints.slice(midIndex);

  return (
    <div
      className="container-fluid"
      style={{ paddingRight: "2%", paddingLeft: "2%" }}
    >
      <section className="aboutus-section" id="about">
        <h2>What we offer</h2>
        <div className="tab-content">
          <div className="tab-bar">
            <div
              className={`tab-button ${
                activeTab === "website" ? "active" : ""
              }`}
              onClick={() => setActiveTab("website")}
            >
              Website
            </div>
            <div
              className={`tab-button ${activeTab === "mobile" ? "active" : ""}`}
              onClick={() => setActiveTab("mobile")}
            >
              Mobile Application
            </div>
          </div>

          <div className="content">
            <div className="row">
              <div className="col-md-8 col-sm-12">
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <div className="bullet-points-section">
                      <ul>
                        {firstHalf.map((point, index) => (
                          <li className="feature-box row" key={index}>
                            <div className="iconcont">
                              <FontAwesomeIcon className="icon" icon={faCheck} />
                            </div>
                            {point}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="bullet-points-section">
                      <ul>
                        {secondHalf.map((point, index) => (
                          <li className="feature-box row" key={index}>
                            <div className="iconcont">
                              <FontAwesomeIcon className="icon" icon={faCheck} />
                            </div>
                            {point}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-sm-12">
                <div className="carousel-section">
                  <Carousel indicators={false}>
                    {currentContent.images.map((image, index) => (
                      <Carousel.Item key={index}>
                        <img
                          className="d-block w-100"
                          src={image}
                          alt={`Slide ${index + 1}`}
                        />
                      </Carousel.Item>
                    ))}
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;
