// src/components/Services.js
import React from "react";
import TextBox from "../TextBox";

const Contact = () => {
  return (
    <div
      className="container-fluid"
      style={{ paddingRight: "2%", paddingLeft: "2%" }}
    >
      <section className="services-section" id="contact">
        <h2>Contact Us</h2>
        <div className=" feature-box container" style={{ padding: "4%" }}>
          <div className="row w-100">
            {" "}
            <div className="col-md-6 col-sm-12">
              <TextBox
                type="text"
                color={"primary"}
                label={"Name"}
                placeholder="Enter Name"
              />
            </div>
            <div className="col-md-6 col-sm-12">
              <TextBox
                type="text"
                label={"Clinic Name"}
                placeholder="Enter Clinic Name"
              />
            </div>
          </div>
          <div className="row w-100">
            <div className="col-md-6 col-sm-12">
              <TextBox type="text" label={"Email"} placeholder="Enter Email" />
            </div>
            <div className="col-md-6 col-sm-12">
              <TextBox type="text" label={"Phone"} placeholder="Enter Phone" />
            </div>
          </div>
          <div className="row w-100">
            <div className="col-md-12 col-sm-12">
              <TextBox
                type="text"
                label={"Message"}
                placeholder="Enter Message"
              />{" "}
            </div>
          </div>
          <div className="btn-wrapper pt-4 d-flex justify-content-end">
          <button className=" btn main-btn">Submit</button></div>

        </div>
      </section>
    </div>
  );
};

export default Contact;
