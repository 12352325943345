// src/App.js
import React from 'react';
import Navbar from './Components/Navbar';
import HeroSection from './Components/HeroSection';
import Services from './Components/Services';
import Features from './Components/Features';
import AboutUs from './Components/AboutUs';
import Contact from './Components/Contact';
import Footer from './Components/Footer';
// src/App.js
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Packages from './Components/Packages';
import Values from './Components/Values';


const App = () => {
  return (
    <div className="App">
      <Navbar />
      <HeroSection />
      <Services />
      <Values/>
      {/* <Features /> */}
      <AboutUs />
      <Packages/>
      <Contact/>
      <Footer/>

    </div>
  );
};

export default App;
